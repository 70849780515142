import request from '@/utils/request';
import { IAppInfo } from 'types/store';
export function loginByDev(params?: any) {
  return request.post<null>('/api/v1/login', params);
}
export function loginOut() {
  return request.get<null>('/accounts/logout');
}
export function editProfile(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_profile', params);
}
export function getAppInfo() {
  return request.post<null, IAppInfo>('/api/v1/app_info');
}
export function switchUser(params: { user_id: number }) {
  return request.post<null>('/api/v1/switch_user', params);
}
export function global_search(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/global_search', params);
}
export function edit_custom_menu(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/edit_custom_menu', params);
}
export function get_custom_menu_list() {
  return request.post<null, Recordable[]>('/api/v1/get_custom_menu_list');
}
export function delete_custom_menu(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/delete_custom_menu', params);
}
/**
 * @param params 获取返点配置区域
 */
export function get_tt_rebate_config_region_category() {
  return request.post<null, Recordable[]>('/api/v1/get_tt_rebate_config_region_category');
}

export function get_website_title(data: { url: string }) {
  return request.post<null, string>('/api/v1/get_website_title', data);
}
