import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "water_mark_wrapper",
    style: _normalizeStyle({
      'grid-template-columns': `repeat(${_ctx.columns},260px)`,
      'grid-template-rows': `repeat(${_ctx.rows},64px)`,
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "water_mark_item",
        key: item
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.user.name.replaceAll('🔺', '').replaceAll('🚩', '')), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.userInfo.email), 1)
      ]))
    }), 128))
  ], 4))
}