import type { Router } from 'vue-router';
import { getCookie } from '@/utils/cookies';
import NProgress from 'nprogress';
import { isDev, isLocal } from '@/utils/const';
import { loginByDev } from '@/api/app';
import { appStore } from '@/store/modules/app';
import { cacheStore } from '@/store/modules/cache';
import { isEmptyData } from '@/utils/common';
import { pathToRegexp } from 'path-to-regexp';
import { addRoutesByCustomReportList } from '..';
const whiteList = ['/login', '/'];
export function createPermissionGuard(router: Router) {
  router.beforeEach(async (to, _from, next) => {
    if (!isEmptyData(cacheStore.appInfo) && !isEmptyData(cacheStore.appInfo?.custom_report_list)) {
      addRoutesByCustomReportList(cacheStore.appInfo?.custom_report_list);
    }

    NProgress.start();
    const is_has_auth = (path) => {
      if (path.includes('business/ugs/open-account')) {
        window.location.href = window.location.href.replaceAll('business/ugs/open-account/', 'business/common/opa/');
      }

      const routes = cacheStore.appInfo!.routes_menus_by_roles.routes!;
      const sub_app_suffix_list = cacheStore.appInfo!.custom_menu_list.map((item) => JSON.parse(item.value).app_root);
      if (sub_app_suffix_list.some((s) => path.includes(s))) {
        if (routes.some((s) => path.includes(s.route_path))) {
          return true;
        } else {
          return false;
        }
      } else {
        for (let i = 0; i < routes.length; i++) {
          const pathToReg = pathToRegexp(routes[i].route_path);
          if (pathToReg.exec(path) && !routes[i].route_path.includes('*')) {
            return true;
          }
        }
      }
      return false;
    };

    if (getCookie('sessionid') && !isDev && !isLocal) {
      if (to.path === '/login') {
        next({ path: '/' });
        NProgress.done();
      } else {
        if (whiteList.includes(to.path) || '/workbench/my/home'.includes(to.path)) {
          next();
        } else {
          if (isEmptyData(cacheStore.appInfo) || isEmptyData(cacheStore.appInfo?.routes_menus_by_roles)) {
            await cacheStore.GetAppInfoAction();
          }

          if (is_has_auth(to.path)) {
            if (to.matched.length === 0) {
              next({ path: to.fullPath });
            } else {
              next();
            }
          } else {
            next({ name: 'NoAuth' });
          }
        }
      }
    } else if (isDev || isLocal) {
      await loginByDev({ code: 'e1929c04-fc21-4240-bfa7-e3299bc9b29d' });
      if (whiteList.includes(to.path)) {
        next();
      } else {
        if (isEmptyData(cacheStore.appInfo) || isEmptyData(cacheStore.appInfo?.routes_menus_by_roles)) {
          await cacheStore.GetAppInfoAction();
        }

        if (is_has_auth(to.path)) {
          if (to.matched.length === 0) {
            next({ path: to.fullPath });
          } else {
            next();
          }
        } else {
          next({ name: 'NoAuth' });
        }
        NProgress.done();
      }
    } else {
      //正服或测服未登录
      resetApp();
      cacheStore.TOGGLE_SIDEBAR_COLLAPSED(false);
      localStorage.removeItem('hwCache');
      localStorage.removeItem('appInfo');
      if (to.path.includes('login')) {
        next();
      } else {
        next(`/login?redirect=${to.fullPath}`);
        NProgress.done();
      }
    }
  });

  router.afterEach((to) => {
    resetApp();
    if (to.meta.title) {
      if (to.meta.title !== 'HW3') {
        document.title = `${to.meta.title}`;
      }
    } else {
      document.title = `Hello WE!`;
    }
    NProgress.done();
  });

  const resetApp = () => {
    appStore.SET_DRAWER_CONFIG(null);
    appStore.SET_ALERT_CONFIG(null);
    appStore.SET_WE_CHANGE(null);
    appStore.SET_WE_DIALOG(null);
  };
}
